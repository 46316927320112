import React from 'react';
import { ColumnLayout, Container, Header, Link, Spinner, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { renderElement, renderField, renderLink } from './tableUtils';
import { buildHostForDomainAndRegion, getDomainFromHost } from '../../utils';
import BrokerTagsComponent from './BrokerTagsComponent';
import { Broker, BrokerEngineType, BrokerMaintenanceInfo, BrokerSummary, DRMode, EC2Instance } from '@amzn/amazonmq-opsconsole-client';
import { formatDateToString, formatMaintenanceWindowDate, getNextMWDate, parseMaintenanceWindowStartTime } from '../../date-utils';


type Props = {
    broker: Broker,
    brokerMaintenance: BrokerMaintenanceInfo,
    brokerInstances: EC2Instance[] | undefined
}

function renderBrokerName(brokerSummary: BrokerSummary): React.ReactElement {
    if (brokerSummary.brokerState === 'AVAILABLE') {
        return <span>{brokerSummary.name} <StatusIndicator type='success'>{brokerSummary.brokerState}</StatusIndicator></span>
    } else if (brokerSummary.brokerState.indexOf('FAILED') >= 0) {
        return <span>{brokerSummary.name} <StatusIndicator type='error'>{brokerSummary.brokerState}</StatusIndicator></span>
    } else {
        return <span>{brokerSummary.name} <StatusIndicator type='info'>{brokerSummary.brokerState}</StatusIndicator></span>
    }
}

function renderCRDRSection(brokerSummary: BrokerSummary) : React.ReactNode {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <span>Running: {brokerSummary.drMode}</span>
            <span>Pending: {brokerSummary.pendingDrMode}</span>
            {brokerSummary.replicationRole &&
                <span>Replication Role: {brokerSummary.replicationRole}</span>
            }
            {brokerSummary.drCounterPart &&
                <Link external={true} target='_blank' href={`https://${buildHostForDomainAndRegion(getDomainFromHost(), brokerSummary.drCounterPart.region)}/broker/${brokerSummary.drCounterPart.brokerId}`}>Counterpart</Link>
            }
        </div>
    )
}

const BrokerSummaryTable : React.FC<Props> = ({broker, brokerMaintenance, brokerInstances}) => {

    const brokerSummary = broker.summary;
    const localDate = new Date();
    let nextCfnMwDate: Date | null = null;
    const parsedMW = parseMaintenanceWindowStartTime(brokerMaintenance.maintenanceWindow);
    const formattedMW = parsedMW ? formatMaintenanceWindowDate(parsedMW) : brokerMaintenance.maintenanceWindow;
    if (parsedMW) {
        nextCfnMwDate = getNextMWDate(broker.id, localDate, parsedMW );
    }
    const nextCfnMwDateDisplay = nextCfnMwDate ? formatDateToString(nextCfnMwDate) : 'Condition not met';
    let content : React.ReactElement[] = [];
    content.push(renderElement("Name", renderBrokerName(brokerSummary), brokerSummary.name));
    content.push(renderField("Host Instance Type", brokerSummary.hostInstanceType, "Pending: " + broker.brokerInfo.pendingHostInstanceType));
    content.push(renderField("Quarantine States", brokerSummary.quarantineStates));
    content.push(renderLink("Customer Account ID", brokerSummary.accountId, brokerSummary.accountLink, "Account Link"));
    content.push(renderField("Engine", broker.brokerInfo.engine, "Pending: " + broker.brokerInfo.pendingEngine));
    content.push(renderField("Deployment Mode", brokerSummary.deploymentMode));
    content.push(renderField("Maintenance Window Start Time", formattedMW));
    content.push(renderLink("Dataplane Cell ID", broker.brokerInfo.cellId, broker.brokerInfo.cellIsenLink, "Cell Link"));
    if (brokerSummary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        content.push(<BrokerTagsComponent brokerId={broker.id} />);
    }
    content.push(renderElement("Node Mapping", renderNodeMapping(brokerInstances), brokerNodeMappingCopyText(brokerInstances)));
    // Next CFN MW should be insync with `SchedulePatchingConditionRetriever`. Modify this accordingly.
    // TODO: Get Next CFN MW from `SchedulePatchingConditionRetriever`
    if (broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        content.push(renderField("Next CFN MW", nextCfnMwDateDisplay));
    }
    if (brokerSummary.brokerEngineType === BrokerEngineType.ACTIVEMQ) {
        content.push(renderField("Broker Instance Internal Tags",
            brokerSummary.brokerInstanceInternalTags.length > 0 ? brokerSummary.brokerInstanceInternalTags.join(", ") : "None"));
    }
    content.push(renderLink("Region", broker.regionName, `https://${buildHostForDomainAndRegion(getDomainFromHost(), broker.regionName)}`));
    if (brokerSummary.drMode !== DRMode.NONE) {
        content.push(renderElement("DR Mode", renderCRDRSection(brokerSummary), ""))
    }

    return (
        <Container header={
            <Header variant="h2">
                Broker Summary
            </Header>
        }>
            <ColumnLayout columns={4} variant="text-grid">
                {content}
            </ColumnLayout>
        </Container>
    )
}

function renderNodeMapping(brokerInstances: EC2Instance[] | undefined): React.ReactNode {
    if (brokerInstances === undefined) {
        return <Spinner />
    } else {
        let ret: React.ReactElement[] = [];
        for (let i = 0; i < brokerInstances.length; i++) {
            ret.push(<span key={i}>{brokerInstances[i].instanceId + " --> " + brokerInstances[i].privateIp}<br /></span>);
        }
        return ret;
    }
}

function brokerNodeMappingCopyText(brokerInstances: EC2Instance[] | undefined): string {
    if (brokerInstances) {
        return brokerInstances
            .map(brokerInstance => `${brokerInstance.instanceId} --> ${brokerInstance.privateIp}`)
            .join("\n");
    }
    return "Node Mapping not available";
}


export default BrokerSummaryTable;